import React, { useState, useEffect,useRef } from 'react';
import {useNavigate} from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap'
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import changepass2 from '../../Assets/Images/changepass2.png';
import PasswordChecklist from "react-password-checklist";
import './ChangePassword.css';
import OtpInputModal from '../Company/OtpInputModal';
import ProjectsService from '../../Services/ProjectsService';
import UserService from '../../Services/UserService';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { STS_LOGOUT_URL,IDENTITY_LOGOUT_URL ,CHANGE_PASSWORD} from "../../Config/AppConfiguration";
import Cookies from 'universal-cookie';
import login from '../../Components/_Authentication/Login';
import { Outlet} from "react-router-dom";
import Logout from '../_Authentication/Logout';
//import {calledFromChangePassword} from '../../Config/Constant';
import { render } from '@testing-library/react';




  

var ChangePasswordUrlTest = CHANGE_PASSWORD;

const ChangePassword = () => {

  const navigate = useNavigate();
    const [repeatPassword, setRepeatPassword] = useState('');
    const [password, setPassword] = useState('');
    const[calledFromChangePassword,setCalledFromChangePassword] = useState(false)
    
    const[isFormFieldsValid,setIsFormFieldsValid] = useState('false')
  
  const [modalShow, setModalShow] = React.useState(false);
  const [OtpModalOpen, setOtpModalOpen] = useState(false);
  const[OtpDataFromModal,setOtpDataFromModal] = useState([]);
  const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false);
  const [authenticated, setauthenticated] = useState(false);
  const [passwordChangeConfirmationMsg, setPasswordChangeConfirmationMsg] = useState("");
  

  //LOGOUT
  const [isLogout, setIsLogout] = useState(false);
  const cookieNameUser = "userId";
  const cookieNameToken = "accessToken";
  const cookieNameCurrentUser = "currentAPIMUser";
const iconStyle = {
    opacity: '1',
};
const [mountedCount, setMountedCount] = useState(0);
 const handleIframeLoad = () => {
  let count=mountedCount+1;
  setMountedCount(count);
  //alert(count);
}
const stsLogoutUrl = STS_LOGOUT_URL;
const identityLogoutUrl = IDENTITY_LOGOUT_URL;
    
 const logoutOtherURL = {
 iFrameSts :'<iframe src="' +
        stsLogoutUrl +
        '" frameborder="0" scrolling="no" style="display:none" width="100" height="100">',
 iFrameIdentity : '<iframe src="' +
        identityLogoutUrl +
        '" frameborder="0" scrolling="no" style="display:none" width="100" height="100">'
  };

const callLogin =  ()  =>{
  const cookies = new Cookies();
  if(cookies.get(cookieNameToken) == null){
    console.log('------------no cookie------------');
    window.location.href = login.getLoginUrl();
    setauthenticated(true);
    
  };
};

  //LOGOUT 
 
        function Iframe(props) {
          return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
        }
const sleep = ms => new Promise(r => setTimeout(r, ms));
const handleLogout =  async()  =>{
  const cookies = new Cookies();
  if(cookies.get(cookieNameToken) !== null){
    cookies.remove(cookieNameToken);
    cookies.remove(cookieNameUser);
    cookies.remove(cookieNameCurrentUser);
    await sleep(3000);
    setIsLogout(true);
  }
}

const signOutHandler = async ()=> {
  await UserService.logoutUser().then((response) => {
    handleLogout();
    console.log('logout success from change password');
    navigate('/', { replace: true });
    window.location.reload();
  }).catch(error =>{
    console.log(error);
  });
}
useEffect(() => {
  if(authenticated===false)
  {
    const timeout = setTimeout(() => {
      // 👇️ redirects to nets login url
      callLogin();
    }, 4000);

    return () => clearTimeout(timeout);
  }
    
  },[])
  const handleOtp = (OtpDataToProject) => {
    setOtpDataFromModal(OtpDataToProject);
  }
  
  

  
  
  let captchaReference = useRef()
  
    console.log("password ---"+password)
    console.log("repeatPassword ---"+repeatPassword)
    let userDetailsObject = {
        password: ""
      };
      userDetailsObject.password=password;
    // Style for the strikethrough effect 
    const strikethroughStyle = { 
        textDecoration: 'line-through', 
    };
    var otpInputModelOpenendForAction = '';
 
  
    function onChange(value) {
        
       // console.log("recaptchaInstance.current.getValue()"+captchaReference.current.getValue());
    //    if(emailId!==null && displayName!==null && countryCode !==null && phoneNumber!==null)
    //    {setIsFormFieldsValid(true)

        
    // }
         
        
        
      }
      
      async function verifyOtp(OtpDataFromModal){
  
        if(OtpDataFromModal.length!==0){
        
          console.log(OtpDataFromModal)
          OtpDataFromModal  = Number(OtpDataFromModal.join(''));
          //alert("otp:"+OtpDataFromModal)
         // alert("userDetailsObject.password"+userDetailsObject.password);
          try {
                const response=await  axios.put(ChangePasswordUrlTest,userDetailsObject,{ headers: {'Content-Type': 'application/json; charset=utf-8','X-StrongConfirmationCode':OtpDataFromModal} });
               if(response){
                setPasswordChangeConfirmationMsg(response.data.message);
                setIsUpdateButtonActive(false); 
                setauthenticated(true);
                setModalShow(true);
                setOtpModalOpen(false);
                
            }
           
                
            } catch (error) {
              setOtpModalOpen(true);
              toast.error(error.response.data.message, {

                position: toast.POSITION.BOTTOM_RIGHT,
      
                autoClose: 8000, //6 seconds
      
                hideProgressBar: false,
      
                closeOnClick: true,
      
                pauseOnHover: true,
      
                draggable: true,
      
                transition: Slide
      
              });
                console.log(error);
            }
           //setModalShow(true);  //enable here for modal testing
          
        
         }
        
          }

     
      function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
            backdrop='static' 
            keyboard="False"
            dialogClassName='my-modal'
           

    
          >
            <Modal.Header className='modal-header'>
              <Modal.Title id="contained-modal-title-vcenter">
              Confirmation of Changes Password Request
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
              <p>
             {passwordChangeConfirmationMsg+ ", you will be redirected to the login page, please login with the new password"}
             </p>
            </Modal.Body>
            <Modal.Footer>
            <button className="ui button comm-button-style primary" onClick={() => signOutHandler()}>LOGOUT</button>
            </Modal.Footer>
          </Modal>
        );
      }
      async function handleSubmit() {
       
        console.log("HI FROM CHange password")
        //e.preventDefault();
        setIsUpdateButtonActive(true);
        //userDetailsObject.password=password;
        console.log("userDetailsObject.password"+userDetailsObject.password)
        otpInputModelOpenendForAction = "changePassword";
        setOtpModalOpen(true);
        // 
        try {
            const response = await ProjectsService.getStrongConfirmationCode("PUT",null,"changePassword",null,null,null)
        if (response && response.data.channel == "SMS") {
            otpInputModelOpenendForAction = "changePassword";
            
            setOtpModalOpen(true);
           // alert("otp entered from otp modal for deleting credentials is ---->" + OtpDataFromModal);
          }
       
            
        } catch (error) {
            console.log(error);
        }
       // setModalShow(true);  //enable here for modal testing
       
      }
 
        return (
            

            <div id="joinUsContainer" className="container-fluid" style={{height:'100%'}}>
                <div className="row" style={{minHeight: '110px', backgroundColor: 'white'}} >
<div className="empty-cont-header" style={{minHeight: '10px'}}>
  <h1>
CHANGE PASSWORD
</h1>
</div>

</div>

    
<div className="main-content">
<section className="change-pass-wrap">
            <div className="cp-left-sec">
            {
 password.length===0 ?
 <img src={changepass2} alt="Change password" title="Change Password" />
 :
 
              
              <div className="pass-check">
                {/* <h3>Passwords must contain</h3> */}
                <ul>
                
         <PasswordChecklist
            rules = {[
               "capital",
               "match",
               "specialChar",
               "minLength",
               "lowercase",
               "number",
            ]}
            iconSize={15}
            minLength = {8}
            value = {password}
            valueAgain = {repeatPassword}
            messages = {{
               minLength: "The minimum length of the password should be 8.",
               specialChar:
               "The password should contain at least one special character.",
               number: "The password should contain at least one numeric letter.",
               capital: "The password should contain at least one uppercase letter.",
               match: "Password and repeat password should match.",
               lowercase: "The password should contain at least one lowercase letter.",
            }}
         />
                </ul>
              </div>
}
            </div>
            <ul className="pass-elements">
            <li>
                    <div className="custom-input-wrapp">
                    <FloatingLabel
        controlId="passwordInput"
        label="Enter new password"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setPassword(event.target.value)}
      >
        <Form.Control type="password" placeholder=" " />
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Password is required.</span>
      </FloatingLabel>
                    </div>
                  </li>
                  <li>
                    <div className="custom-input-wrapp">
                    {password.length!==0 ?<FloatingLabel
        controlId="passwordInput"
        label="Repeat password"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setRepeatPassword(event.target.value)}
      >
        <Form.Control type="password" placeholder=" " />
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Repeat password is required.</span>
      </FloatingLabel> : <></>}
                    </div>
                  </li>
     
              <li>
                <div className="apim-pop-button-w comm-button-wrap">
                  <button className="ui button comm-button-style primary apim-reset-pass-btn btn-disable" id="updateBtn"  onClick={handleSubmit}>Update</button>
                  <button className="ui button comm-button-style secondary custom-loader" id="loadingBtn" style={{display: 'none'}}>
                    <i className="icon-plus iconLoader" />
                    Changing Password..
                  </button>
                  <button className="ui button comm-button-style secondary apim-pop-button-cancel" id="cancelBtn">Cancel</button>
                </div>
              </li>
            </ul>
          </section>
          <div className="toast-container"><ToastContainer limit={4}/></div>
          <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      
          {OtpModalOpen &&<OtpInputModal ModalHeading={""} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal}  verifyOtp={verifyOtp} credentialRecord={""} otpInputModelOpenendForAction={otpInputModelOpenendForAction}/>}
              </div>
              <Outlet />
              
    <div style={{display : 'none'}}>
     {isLogout === false
     ?<></>
     :<>
      <iframe width="560"
                        height="315"
                        src={stsLogoutUrl}
                        title="stsLogout"
                        onLoad={handleIframeLoad}
                         > 
                </iframe> 
                <iframe width="560"
                        height="315"
                        src={identityLogoutUrl}
                        title="identityLogout" 
                        onLoad={handleIframeLoad}
                        > 
                </iframe> 
        
     </>
     }
    </div>
    {mountedCount==2 ?window.location.reload() :<></>}
            </div>
          );
    
}

export default ChangePassword;





