
import Cookies from 'universal-cookie';
import { REDIRECT_URL_DEVELOPER,AUTH_URL } from "../../Config/AppConfiguration";

class Login {
  //  calledFromChangePassword;
  //   setIsCalledFromChangePassword(value)
  //   {

  //           this.calledFromChangePassword = value;
  //           alert("alert(this.calledFromChangePassword"+this.calledFromChangePassword )
  //   }

  getLoginUrl = function () {

	console.log("Property file URL one:---->"+REDIRECT_URL_DEVELOPER);
	console.log("Property file URL two:---->"+AUTH_URL);
return (
   AUTH_URL +
   "&redirect_uri=" +
   encodeURIComponent(REDIRECT_URL_DEVELOPER) +
   "&nonce=" +
   btoa(Math.random()).slice(0, 24)
 )

    // if(this.calledFromChangePassword === true)
    // {
    //   let URL=    "http://localhost:3000/changePassword/#access_token=e496bb25-4f8d-34c0-b741-891203c2c1ae&id_token=eyJ4NXQiOiJNelJtWkdabU1EZzNPVFprTWpJNE1XSXpOREl4Tm1GalpqTXpNVEU0WXpZellUQXlOakV5Tm1ZMU9XUTBOV1V6TXpFME9HUmtOV1V3WXpCaE5EUXpPUSIsImtpZCI6Ik16Um1aR1ptTURnM09UWmtNakk0TVdJek5ESXhObUZqWmpNek1URTRZell6WVRBeU5qRXlObVkxT1dRME5XVXpNekUwT0dSa05XVXdZekJoTkRRek9RIiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiWHZFVzlTcnFSSU0wMnlNZE85S0tVdyIsImF1ZCI6WyJISnRPS1NuZnU1QWpwaHdpRVpBcmZ4dWR5S1VhIiwiaHR0cDpcL1wvb3JnLndzbzIuYXBpbWd0XC9nYXRld2F5Il0sInN1YiI6InNoYXJtYXNhdGVlc2gyMDIyQGdtYWlsLmNvbSIsImF6cCI6IkhKdE9LU25mdTVBanBod2lFWkFyZnh1ZHlLVWEiLCJhbXIiOlsiT3BlbklEQ29ubmVjdEF1dGhlbnRpY2F0b3IiXSwiaXNzIjoiaHR0cHM6XC9cL2FwaS1pZGVudGl0eS1wcC5uZXRzLmV1XC9vYXV0aDJcL3Rva2VuIiwiZXhwIjoxNjkzOTIyMDQ5LCJpYXQiOjE2OTM5MTg0NDksIm5vbmNlIjoiTUM0Mk56ZzNPVFEyT0RnMk1UazVNek0wIiwic2lkIjoiODQ3YzRiMjktZGZiMi00ODY0LTkzMzUtZmUyNDE2ZmIxMTNjIn0.jn1MCEzaiCGS7Un2m2eP1I6sXPcVpI6v9zWekhpbrpC2J0cISbyDDcaJy6bFIDvc27VA4LsrR5hIFMxEq4FXXBwE9z10ovf110GmKw0My9yhaxNdf0NPZWHu_prV7X_GeQjgbcbj1bCmWL-ehMVRP9FoU-z0E-DFXHv02eYMksSN57ufzrYY7ndiTIuRF5CVzbmgbIqeStk222cZCUjLYTmn20xgMrDFB0F8rg_jYk6H4kvNyqQiWVF4yClcFo3qVJXp32WRx1d0k1NdadJVKBwC7NztA4sCqLR2HSVsDv9GdMq7gLWaE0CpF0wspXNwAlNiKNCBT-iX0CLdajX7aA&token_type=Bearer&expires_in=3599&session_state=6be980b6757dc736266d4c4059aa49a100ad006e611ceae28560ab2ee1134c17.eoooMMovdcaAG0-nZGsqYg";
    // }

    //uncomment below lines while running on localhost
    // let URL = "http://localhost:3000/developer/#access_token=e8da016d-c3f8-3661-a9a0-5ab400354295&id_token=eyJ4NXQiOiJNelJtWkdabU1EZzNPVFprTWpJNE1XSXpOREl4Tm1GalpqTXpNVEU0WXpZellUQXlOakV5Tm1ZMU9XUTBOV1V6TXpFME9HUmtOV1V3WXpCaE5EUXpPUSIsImtpZCI6Ik16Um1aR1ptTURnM09UWmtNakk0TVdJek5ESXhObUZqWmpNek1URTRZell6WVRBeU5qRXlObVkxT1dRME5XVXpNekUwT0dSa05XVXdZekJoTkRRek9RIiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiWHZFVzlTcnFSSU0wMnlNZE85S0tVdyIsImF1ZCI6WyJISnRPS1NuZnU1QWpwaHdpRVpBcmZ4dWR5S1VhIiwiaHR0cDpcL1wvb3JnLndzbzIuYXBpbWd0XC9nYXRld2F5Il0sInN1YiI6InNoYXJtYXNhdGVlc2gyMDIyQGdtYWlsLmNvbSIsImF6cCI6IkhKdE9LU25mdTVBanBod2lFWkFyZnh1ZHlLVWEiLCJhbXIiOlsiT3BlbklEQ29ubmVjdEF1dGhlbnRpY2F0b3IiXSwiaXNzIjoiaHR0cHM6XC9cL2FwaS1pZGVudGl0eS1wcC5uZXRzLmV1XC9vYXV0aDJcL3Rva2VuIiwiZXhwIjoxNjkzOTIyMDQ5LCJpYXQiOjE2OTM5MTg0NDksIm5vbmNlIjoiTUM0Mk56ZzNPVFEyT0RnMk1UazVNek0wIiwic2lkIjoiODQ3YzRiMjktZGZiMi00ODY0LTkzMzUtZmUyNDE2ZmIxMTNjIn0.jn1MCEzaiCGS7Un2m2eP1I6sXPcVpI6v9zWekhpbrpC2J0cISbyDDcaJy6bFIDvc27VA4LsrR5hIFMxEq4FXXBwE9z10ovf110GmKw0My9yhaxNdf0NPZWHu_prV7X_GeQjgbcbj1bCmWL-ehMVRP9FoU-z0E-DFXHv02eYMksSN57ufzrYY7ndiTIuRF5CVzbmgbIqeStk222cZCUjLYTmn20xgMrDFB0F8rg_jYk6H4kvNyqQiWVF4yClcFo3qVJXp32WRx1d0k1NdadJVKBwC7NztA4sCqLR2HSVsDv9GdMq7gLWaE0CpF0wspXNwAlNiKNCBT-iX0CLdajX7aA&token_type=Bearer&expires_in=3599&session_state=6be980b6757dc736266d4c4059aa49a100ad006e611ceae28560ab2ee1134c17.eoooMMovdcaAG0-nZGsqYg";
    // return URL;
  }

  loginAndRedirectToChangePassword = function (REDIRECT_URL_DEVELOPER) {
    console.log("redirecturl in chnagepassword method:---->"+REDIRECT_URL_DEVELOPER);
    var redirTo = REDIRECT_URL_DEVELOPER;
    var authUrl = AUTH_URL;
return (
   authUrl +
   "&redirect_uri=" +
   encodeURIComponent(redirTo) +
   "&nonce=" +
   btoa(Math.random()).slice(0, 24)
 )

    

    //uncomment below lines while running on localhost
    // let URL = "http://localhost:3000/developer/#access_token=b815a891-36c7-3fa8-b1c6-99136198b9c3&id_token=eyJ4NXQiOiJNelJtWkdabU1EZzNPVFprTWpJNE1XSXpOREl4Tm1GalpqTXpNVEU0WXpZellUQXlOakV5Tm1ZMU9XUTBOV1V6TXpFME9HUmtOV1V3WXpCaE5EUXpPUSIsImtpZCI6Ik16Um1aR1ptTURnM09UWmtNakk0TVdJek5ESXhObUZqWmpNek1URTRZell6WVRBeU5qRXlObVkxT1dRME5XVXpNekUwT0dSa05XVXdZekJoTkRRek9RIiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiWHZFVzlTcnFSSU0wMnlNZE85S0tVdyIsImF1ZCI6WyJISnRPS1NuZnU1QWpwaHdpRVpBcmZ4dWR5S1VhIiwiaHR0cDpcL1wvb3JnLndzbzIuYXBpbWd0XC9nYXRld2F5Il0sInN1YiI6InNoYXJtYXNhdGVlc2gyMDIyQGdtYWlsLmNvbSIsImF6cCI6IkhKdE9LU25mdTVBanBod2lFWkFyZnh1ZHlLVWEiLCJhbXIiOlsiT3BlbklEQ29ubmVjdEF1dGhlbnRpY2F0b3IiXSwiaXNzIjoiaHR0cHM6XC9cL2FwaS1pZGVudGl0eS1wcC5uZXRzLmV1XC9vYXV0aDJcL3Rva2VuIiwiZXhwIjoxNjkzOTIyMDQ5LCJpYXQiOjE2OTM5MTg0NDksIm5vbmNlIjoiTUM0Mk56ZzNPVFEyT0RnMk1UazVNek0wIiwic2lkIjoiODQ3YzRiMjktZGZiMi00ODY0LTkzMzUtZmUyNDE2ZmIxMTNjIn0.jn1MCEzaiCGS7Un2m2eP1I6sXPcVpI6v9zWekhpbrpC2J0cISbyDDcaJy6bFIDvc27VA4LsrR5hIFMxEq4FXXBwE9z10ovf110GmKw0My9yhaxNdf0NPZWHu_prV7X_GeQjgbcbj1bCmWL-ehMVRP9FoU-z0E-DFXHv02eYMksSN57ufzrYY7ndiTIuRF5CVzbmgbIqeStk222cZCUjLYTmn20xgMrDFB0F8rg_jYk6H4kvNyqQiWVF4yClcFo3qVJXp32WRx1d0k1NdadJVKBwC7NztA4sCqLR2HSVsDv9GdMq7gLWaE0CpF0wspXNwAlNiKNCBT-iX0CLdajX7aA&token_type=Bearer&expires_in=3599&session_state=6be980b6757dc736266d4c4059aa49a100ad006e611ceae28560ab2ee1134c17.eoooMMovdcaAG0-nZGsqYg";
    // return URL;
  }

  currentUserTokenExpired = function () {
    var _user_attributes = new Cookies().get("currentAPIMUser");
    if (_user_attributes) {
      _user_attributes = decodeURI(_user_attributes);
      const currentUserFromCookie = JSON.parse(_user_attributes);
      if (currentUserFromCookie.accessTokenExpiry != null) {
        let accessTokenExpiryFromCookie = new Date(
          currentUserFromCookie.accessTokenExpiry
        );
        if (typeof accessTokenExpiryFromCookie.getMonth == "function") {
          if (accessTokenExpiryFromCookie > new Date()) {
            return false;
          }
        }
      }
    }
    return true;
  };

  getParameterByName = function (name) {
    // name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&#]" + name + "(=([^&#]*)|&|#|$)"),
      ful = regex.exec(window.location.href);
    if (!ful) return null;
    if (!ful[2]) return "";
    return decodeURIComponent(ful[2].replace(/\+/g, " "));
  };

  setCurrentUserObject = function (data, accessTokenExpiresIn) {
    return {
      accountStatus: data.accountStatus,
      displayName: data.displayName,
      email: data.email,
      id: data.id,
      lastLogin: data.lastLogin,
      mobileNumber: data.mobileNumber,
      accessTokenExpiry: new Date().setSeconds(accessTokenExpiresIn),
    };
  }
}
export default new Login();