import axios from 'axios';
import {PROJECTS_API,APP_URL, COMPANIES_API} from '../Config/AppConfiguration';
import { Buffer } from 'safe-buffer';

import Cookies from 'universal-cookie';

class ProjectService{

    getUserAccessToken(){
        const cookies = new Cookies();
        return cookies.get("accessToken");
    }
    // getBearerToken(){ 
    //     var BEARER_TOKEN = 'Bearer '.concat(this.getUserAccessToken()); 
    //     return BEARER_TOKEN;
    // }
 
getAllProjects(){
    return  axios.get(PROJECTS_API, { headers: {'Content-Type': 'application/json'} })
}
getProjectsDashboardByProjectId(id){
    const URL=PROJECTS_API.concat(id).concat('/dashboard');
    return  axios.get(URL, { headers: {'Content-Type': 'application/json'} })
}
addNewProjectMemberByProjectId(id,email){
   // alert("email inside project service==="+email);
    const URL=PROJECTS_API.concat(id).concat('/members/');
    return  axios.post(URL,{ email: email },{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':1} })
}

deleteSbxProjectByProjectId(id){
    // alert("email inside project service==="+email);
     const URL=PROJECTS_API.concat(id)
     return  axios.delete(URL,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':1} 
    })
}
deleteCompanyProjectByProjectId(companyId,projectId){
    // alert("email inside project service==="+email);
    const URL=COMPANIES_API.concat(companyId).concat('/projects/').concat(projectId);
     return  axios.delete(URL,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':1} 
    })
}
addNewSbxProject(projectName) //(projectType,id,projectName)
{
    
    return  axios.post(PROJECTS_API,{ projectName: projectName },{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':1} })
    
   
}
addNewTstProject(projectName,id) //(projectType,id,projectName)
{

   if(id!==null){
        //add new company project
        const URL=COMPANIES_API.concat(id).concat('/projects/');
    return  axios.post(URL,{ projectName: projectName },{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':1} })
    }

}
createNewProjectCredential(id,state,confirmationCode)
{
    
   // alert("createNewProjectCredential inside project service, environment==="+JSON.stringify(state));
   // alert("Confirmation code in the service is "+confirmationCode)
    const URL=PROJECTS_API.concat(id).concat('/credentialSets/').concat(state).concat('/');
    if(state==="Production" && confirmationCode!==null)
    {
        return  axios.post(URL,{},{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
    }
    else if(state==='Test' || state==='Sandbox')
    {
        return  axios.post(URL,{},{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
    }
   
}
viewProjectCredential(id,state,confirmationCode,createdDate)
{
   // alert("viewProjectCredential inside project service, environment==="+JSON.stringify(state));
   // alert("Confirmation code in the service is "+confirmationCode)
    const URL=PROJECTS_API.concat(id).concat('/credentialSets/').concat(state).concat('/');
    if(state==="Production" && confirmationCode!==null)
    {
        alert("prod ----------------")
        return  axios.post(URL,{},{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
    }
    else if(state==='Test' || state==='Sandbox')
    {
        alert("test or sand box ----------------")
        return  axios.post(URL,{},{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
    }
}
//removeAccess
removeProjectCredential(id,state,confirmationCode,createdDate)
{
    
   // alert("removeProjectCredential inside project service, environment==="+JSON.stringify(state));
   // alert("Confirmation code in the service is "+confirmationCode)
    var createdDateInLong = new Date(createdDate);
    createdDateInLong = createdDateInLong.getTime();
   
   
    if(state==="Production" && confirmationCode!==null)
    {
        const URL=PROJECTS_API.concat(id).concat('/credentialSets/').concat(state).concat('/').concat(createdDateInLong);;
        return  axios.delete(URL,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
    }
    else if(state==='Test' || state==='Sandbox')
    {
        const URL=PROJECTS_API.concat(id).concat('/credentialSets/').concat(state).concat('/').concat(createdDateInLong);
        return  axios.delete(URL,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
    }
   
}

//remove All Access
removeAllProjectCredential(id,state,confirmationCode)
{
    // var createdDateInLong = new Date(createdDate);
    // createdDateInLong = createdDateInLong.getTime();
   // alert("removeAllProjectCredential inside project service, environment==="+JSON.stringify(state));
   // alert("Confirmation code in the service is "+confirmationCode)
    const URL=PROJECTS_API.concat(id).concat('/credentialSets/').concat(state).concat('/');
    if(state==="Production" && confirmationCode!==null)
    {
        return  axios.delete(URL,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
    }
    else if(state==='Test' || state==='Sandbox')
    {
        return  axios.delete(URL,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
    }
   
}

getStrongConfirmationCode(httpVerbData,id,action,projectMode,createdDate,credentialId)
{

   // alert("getStrongConfirmationCode inside project service, httpVerb==="+httpVerbData);
    const URL=APP_URL.concat('/getStrongConfirmationCode')
    if(httpVerbData==="DELETE")
    {
        var createdDateInLong = new Date(createdDate);
        createdDateInLong = createdDateInLong.getTime();
        if(action==="removeAccess")
        {
            const authURL = '/projects/'.concat(id).concat('/credentialSets/').concat(projectMode).concat('/').concat(createdDateInLong);
            return  axios.post(URL,{authorizationUrl:authURL,httpVerb:httpVerbData},{ headers: {'Content-Type': 'application/json'} })
        }
        else if(action==="removeAllAccess")
        {
            const authURL = '/projects/'.concat(id).concat('/credentialSets/').concat(projectMode).concat('/')
            return  axios.post(URL,{authorizationUrl:authURL,httpVerb:httpVerbData},{ headers: {'Content-Type': 'application/json'} })
        }
       
       
    }
    else if(httpVerbData==="GET")
    {
        if(action==="view")
       {
        
        const authURL = '/projects/'.concat(id).concat('/credentials/').concat(credentialId);
        return  axios.post(URL,{authorizationUrl:authURL,httpVerb:httpVerbData},{ headers: {'Content-Type': 'application/json'} })

       }
       

    }
    else  if(httpVerbData==="PUT")
    {
        if(action==="changePassword")
        {
            
         const authURL = '/users'.concat('/current/').concat('password');
         return  axios.post(URL,{authorizationUrl:authURL,httpVerb:httpVerbData},{ headers: {'Content-Type': 'application/json'} })
 
        }
       
    }
   
    else
    {// if httpVerb is post
       
        if(action==="registerCompany"||action==="apiVersionException"||action==="requestTechnicalAssistance"||action==="other"||action==="contactSales"||action==="reportIssue")
        {
            
         const authURL = '/users'.concat('/current/').concat('serviceRequests/');
         return  axios.post(URL,{authorizationUrl:authURL,httpVerb:httpVerbData},{ headers: {'Content-Type': 'application/json'} })
 
        }
        else if(action==="changePhoneNumber")
        {
            
         const authURL = '/users'.concat('/current/').concat('activatenewmobile');
         return  axios.post(URL,{authorizationUrl:authURL,httpVerb:httpVerbData},{ headers: {'Content-Type': 'application/json'} })
 
        }
        const authURL = '/projects/'.concat(id).concat('/credentialSets/').concat(projectMode).concat('/')
        return  axios.post(URL,{authorizationUrl:authURL,httpVerb:httpVerbData},{ headers: {'Content-Type': 'application/json'} })
    }
    
   
}
getSecretDetailsForCred(id,credId,code)
{
   // alert("getSecretDetailsForCred in service===");
    const URL=PROJECTS_API.concat(id).concat('/credentials/').concat(credId);
    return  axios.get(URL,{ headers: {'X-StrongConfirmationCode':code, 'UserToken': this.getUserAccessToken()} })
}
// createNewProjectCredential(id,state)
// {
//     alert("createNewProjectCredential inside project service, environment==="+state.mode);
//     const URL=PROJECTS_API.concat(id).concat('/credentialSets/').concat(state.mode).concat('/');
//     return  axios.post(URL,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':1, Authorization: BEARER_TOKEN} })
// }

updateEditedProperties(projectId, data, confirmationCode){
    const URL=PROJECTS_API.concat(projectId).concat('/apisAndProperties');
    return  axios.patch(URL,data,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
}

computedProperties(projectId, data, confirmationCode){
   // alert("computedProperties===");
    const URL=PROJECTS_API.concat(projectId).concat('/computedProperties');
    return  axios.post(URL,data,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
}

postWithBasicAuth(URL, uname, pass, reqData){
    //encode basic auth into base64 and pass in the url
    const buff = Buffer.from(uname+":"+pass, "utf8");
    const base64 = buff.toString("base64");
     const encodedAuth = "Basic " + base64;
   
   return axios.post(URL+'?grant_type=client_credentials',reqData,{ headers: {'Content-Type': 'application/json', Authorization: encodedAuth} })
}
//Usertoken:
getViewCredentials(fullURL, accessToken, otp){
   // const URL=PROJECTS_API.concat(id).concat('/dashboard');
    const BEARER_TOKEN = 'Bearer '.concat(accessToken); 
return axios.get(fullURL, { headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':otp, 'Usertoken': this.getUserAccessToken(), Authorization: BEARER_TOKEN} });
}

getProjectsAvailableApisByProjectId(projectId){
    const URL=PROJECTS_API.concat(projectId).concat('/availableApis/');
    return  axios.get(URL, { headers: {'Content-Type': 'application/json'} })
}

getXStrongConfirmationCode(action,httpVerbData, ...param){
    const URL=APP_URL.concat('/getStrongConfirmationCode')
    let authURL='';
    if(action==="C_PROP")
    {
        authURL = '/projects/'.concat(param[0]).concat('/computedProperties');
    }else if(action==="API_PROP"){
        authURL = '/projects/'.concat(param[0]).concat('/apisAndProperties');
    }else if(action==="DEL_P_MEMBER"){
        authURL = '/projects/'.concat(param[0]).concat('/members/').concat(param[1]);
    }else if(action==="EDIT_COM_DESCRIPTION"){
        authURL = '/companies/'.concat(param[0]).concat('/description');
    }else if(action==="INVITE_REP"){
        authURL = '/companies/'.concat(param[0]).concat('/representatives/');
    }else if(action==="PROJECT_PROD_ACCESS"){
        authURL = '/users/current/serviceRequests/';
    } 
    else if(action==="REPLACE_REP"){
    authURL = '/companies/'.concat(param[0]).concat('/representatives/').concat(param[1]);;
}
    return  axios.post(URL,{authorizationUrl:authURL,httpVerb:httpVerbData},{ headers: {'Content-Type': 'application/json'} })
}

deleteProjectMember(projectId, memberId, confirmationCode){
//let confirmationCode = getXStrongConfirmationCode("DEL_P_MEMBER","DELETE",projectId, memberId);
const URL=PROJECTS_API.concat(projectId).concat('/members/').concat(memberId);
return  axios.delete(URL,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
}

getCompaniesCrepDetails(companyId)
{
    const URL=COMPANIES_API.concat(companyId);
    return  axios.get(URL, { headers: {'Content-Type': 'application/json'} })
}

editCompanyDescription(companyId, requestData, confirmationCode){
    const URL=COMPANIES_API.concat(companyId).concat('/description');
    return  axios.put(URL,requestData,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} });
}
inviteRepresentative(companyId, requestData, confirmationCode){
    const URL=COMPANIES_API.concat(companyId).concat('/representatives/');
    return  axios.post(URL,requestData,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} });
}

resendCode(reSendRequest){
    const URL=APP_URL.concat('/getStrongConfirmationCode');
    return  axios.post(URL,reSendRequest,{ headers: {'Content-Type': 'application/json'} })
}

}
export default new ProjectService();