
import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import login from '../../Components/_Authentication/Login';
import {useNavigate } from 'react-router-dom';
import { STS_LOGOUT_URL,IDENTITY_LOGOUT_URL } from "../../Config/AppConfiguration";
import UserService from '../../Services/UserService';

 const Logout = (props) =>{
    const [isLogout, setIsLogout] = useState(props.show);
    const navigate = useNavigate();
    const cookieNameUser = "userId";
    const cookieNameToken = "accessToken";
    const cookieNameCurrentUser = "currentAPIMUser";
    const stsLogoutUrl = STS_LOGOUT_URL;
    const identityLogoutUrl = IDENTITY_LOGOUT_URL;
    
 const [mountedCount, setMountedCount] = useState(0);
 const handleIframeLoad = () => {
  let count=mountedCount+1;
  setMountedCount(count);
  //alert(count);
}

  const logoutOtherURL = {
 iFrameSts :'<iframe src="' +
        stsLogoutUrl +
        '" frameborder="0" scrolling="no" style="display:none" width="100" height="100">',
 iFrameIdentity : '<iframe src="' +
        identityLogoutUrl +
        '" frameborder="0" scrolling="no" style="display:none" width="100" height="100">'
  };
        function Iframe(props) {
          return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
        }
const sleep = ms => new Promise(r => setTimeout(r, ms));
const handleLogout =  async()  =>{
  const cookies = new Cookies();
  if(cookies.get(cookieNameToken) !== null){
    cookies.remove(cookieNameToken);
    cookies.remove(cookieNameUser);
    cookies.remove(cookieNameCurrentUser);
    await sleep(3000);
    setIsLogout(props.isLogout);
  }
}

const signOutHandler = async ()=> {
  await UserService.logoutUser().then((response) => {
    handleLogout();
    console.log('logout success');
    navigate('/', { replace: true });
    window.location.reload();
  }).catch(error =>{
    console.log(error);
  });
}

const cookies = new Cookies();
const [user, setUser] = useState('');
   useEffect(() => {
    if(props.calledFromChangePassword ===true)
    {
      //alert("I will call signout handler now from logout .js"+props.calledFromChangePassword)
      signOutHandler();
    }
    if(login.currentUserTokenExpired()){
      signOutHandler();
    }else{
      var _user_attributes = cookies.get(cookieNameCurrentUser);
    if (_user_attributes) {
      _user_attributes = decodeURI(_user_attributes);
      const user_attributes = JSON.parse(_user_attributes);
      setUser(user_attributes);
    }
    }
    
    },[]);

    return(
      <div>
        <div style={{display : 'none'}}>
        {isLogout === false
        ?<></>
        :<>
        <iframe width="560"
                        height="315"
                        src={stsLogoutUrl}
                        title="stsLogout"
                        onLoad={handleIframeLoad}
                         > 
                </iframe> 
                <iframe width="560"
                        height="315"
                        src={identityLogoutUrl}
                        title="identityLogout" 
                        onLoad={handleIframeLoad}
                        > 
                </iframe> 
        </>
        }
         </div>
        {mountedCount==2 ?window.location.reload() :<></>}
       </div>
    )
}
export default Logout;