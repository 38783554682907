import React, { useState, useEffect } from 'react';
import './CompanyAccordion.css';
import manageUserOrEditCompany from '../../Assets/Images/manageUserOrEditCompany.png';
import companyImage from '../../Assets/Images/companyImage.png';
import plusIcon from '../../Assets/Images/plusIcon.png';
import conicalFlask from '../../Assets/Images/conical-flask.png';
import ProjectsService from '../../Services/ProjectsService';
import ModalDialog from '../Company/ModalDialog';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { render } from '@testing-library/react';
import Cookies from 'universal-cookie';

const CompanyAccordion = () => {
 
  const [modalOpen, setModalOpen] = useState(false);
  const [projects, setProjects] = useState([])
  const [companyId,setCompanyId] = useState('');
  const [companyName,setCompanyName] = useState('');
  const cookieNameCurrentUser = "currentAPIMUser";
  
  const [user, setUser] = useState('');
 

  useEffect(() => {
    const cookies = new Cookies();
    if(cookies!==null){
      var _user_attributes = cookies.get(cookieNameCurrentUser);
    if (_user_attributes) {
      _user_attributes = decodeURI(_user_attributes);
      const user_attributes = JSON.parse(_user_attributes);
      setUser(user_attributes);
    }
  }
    },[]);
  
  useEffect(() => {
    ProjectsService.getAllProjects().then((response) => {
     

      setProjects(response.data.sort())
      console.log(response.data);
      let sortedProjects= [...response.data].sort((a, b) => b[2].name - a[2].name);
      console.log("sorted project names are----->"+sortedProjects)
    }).catch(error =>{
      console.log(error);
    });
  },[])

  
  function privateTabAncor() {
    console.log('privateTabAncor');
    document.getElementById("privateTabButton").disabled=false;
    var privateNav = document.getElementById('pNav');
    privateNav.classList.add("activeNav");
      var sharedNav = document.getElementById("sNav");
      sharedNav.classList.remove("activeNav");
    }
    function privateTabButton() {
    alert('privateTabButton');
    }
    
    function sharedTabAncor() {
    document.getElementById("privateTabButton").disabled=true;
    var privateNav = document.getElementById('pNav');
      privateNav.classList.remove("activeNav");
     var sharedNav = document.getElementById("sNav");
      sharedNav.classList.add("activeNav");
    }
   
    function addNewProjectButton()
{
 alert("you clicked on add new project");
}
function manageUserButton()
{
alert("you clicked on Manageuser/editcompany");
}
const addNewSbxProject = () => {
  setCompanyId(null);
  setCompanyName(null);
  setModalOpen(true);
  
 
 console.log("you clicked on add new project for sandbox");
}
//     function addNewSbxProject()
// {
//   setModalOpen(true);

//  alert("you clicked on add new project for sandbox");
// }
function setCompanyIdandCompanyNameState(id,name)
{
setCompanyId(id);
setCompanyName(name)
}
const addNewTstProject = (companyId,companyName) => {
console.log("companyId=====> inside addNewTstProject"+companyId)
if (companyId !=null)
{
  setCompanyIdandCompanyNameState(companyId,companyName);
}
// console.log("")
setModalOpen(true);

console.log("you clicked on add new project for test");
}



return projects.map(obj => {
  return (
    <div
            id="companyAccordionContainer"
            className="container-fluid"
            style={{ height: "100%",width:"100%"}}
          >
            <div
              className="row"
              style={{ minHeight: "110px", backgroundColor: "white" }}
            >
              <div className="empty-cont-header" style={{ minHeight: "10px" }}>
                <h1 style={{color:'#2d32aa'}}>Welcome {user.displayName}</h1>
              </div>
            </div>
    
    <div className="main-company-content mt-3" style={{minHeight: '250px'}}>
       
    <div className="accordion" id="accordionExample">
    <div className="accordion-item">
        <div className="accordion-header" id="headingOne" style={{borderBottom: '1px solid #6c757d'}}>
          <div className="row m-0">
            <div className="col-1 justify-content-center text-center"><img src={conicalFlask} className="img-responsive" alt="Cinque Terre" width={20} height={25} /> </div>
            <div className="col-9 d-flex justify-content-left text-center "> 
            <span style={{color: '#2d32aa', fontWeight: 'bold'}} className="p-2"><h5>SANDBOX</h5></span>
            </div>
            

<div className="col-1  d-flex justify-content-end mt-2 pe-0"><></></div>
<div className="col-1 d-flex justify-content-center">
<button className="accordion-button collapsed buttonMargin" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
</button>
</div>

          </div>    
          <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{borderTop: '1px solid skyblue'}}>
            <div className="accordion-body">
              <div>
                <div className=" ">
                  {/* Nav pills */}
                  <ul className="nav nav-pills justify-content-center row" role="tablist">
                    <li className="nav-item col-6 tabular-button row activeNav" id="pNav">
                      <div className="col-10 text-center">
                        <a id="privateTabAncor" className=" nav-link  active tb" data-bs-toggle="pill" href="#privateProjects"  onClick={() =>privateTabAncor()}>
                          <b>MY PRIVATE PROJECTS ({obj.personalProjects.length})</b>
                        </a>
                      </div>
                      <div className="col-1 text-center " >
                        <button id="privateTabButton" type="button" style={{border: 'none', backgroundColor: 'white'}} data-bs-toggle='tooltip' data-bs-placement="top"  title='Add new sandbox project' onClick={() => addNewSbxProject()}>
                  {/* <img src={plusIcon} alt="Add new sandbox project" width={30} height={30} />  */}
                  <h4 style={{color:'#2d32aa'}} width={30} height={30}>+</h4> 
                </button>
                      </div>
                    </li>
                    <li className="nav-item col-6 tabular-button row" id="sNav">
                      <div className="col text-center" style={{padding: '0px'}}>
                        <a id="sharedTabAncor" className="nav-link tb" data-bs-toggle="pill" href="#sharedProjects"  onClick={() =>sharedTabAncor()}>
                          <b>PROJECTS SHARED WITH OTHERS ({obj.sharedProjects.length})</b>
                        </a>
                      </div>
                    </li>
                  </ul>
                  {/* Tab panes */}
                  <div className="tab-content">
                    <div id="privateProjects" className="tab-pane active">
                    
            {obj.personalProjects.map( pProject=> {
return (

<div class="apim-project-row " id={pProject.id}>
<div class={pProject.projectMode} data-bs-toggle='tooltip' title={pProject.projectMode}></div>
  <span class="head-sub-title">
    <a class="apim-project-link " href={`/projects/${pProject.companyId}/${pProject.id}`} data-html="">{pProject.name}</a>
    </span>
      </div> 
              );
            })}
                      </div>
                    <div id="sharedProjects" className="tab-pane fade">
            {obj.sharedProjects.map( sProject=> {
return (

<div class="apim-project-row " id={sProject.id}>
<div class={sProject.projectMode} data-bs-toggle='tooltip' title={sProject.projectMode}></div>
  <span class="head-sub-title">
    <a class="apim-project-link " href={`/projects/${sProject.companyId}/${sProject.id}`} data-html="">{sProject.name}</a>
    </span>
      </div>
              );
            })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
         
      obj.companyProjects.map((companyProjects,index) => {
        
              return ( 
                <div className="accordion-item" >
                <div className="accordion-header" id={companyProjects.id} style={{borderBottom: '1px solid #6c757d'}}>
                  <div className="row m-0">
                    <div className="col-1 justify-content-center text-center"><img src={companyImage} className="img-responsive" alt="Cinque Terre" width={20} height={25} /> </div>
                    <div className="col-9 d-flex justify-content-left text-center "> <span style={{color: '#2d32aa', fontWeight: 'bold'}} className="p-2"><h5>{companyProjects.name.toUpperCase()}</h5></span> 
                    {companyProjects && companyProjects.userCanEditDescription
                      ?
                      <>
                      {(companyProjects.userCanAddDelegate && companyProjects.userCanRemoveDelegate)
                      ?
                      <a className="justify-content-right text-center ml-5 mb-1" data-bs-toggle='tooltip' data-bs-placement="top" title='Manage users' type="button" style={{border: 'none', backgroundColor: 'white',margin: '0px 0px 0px 0px'}}
                      href={`/editCompany/${companyProjects.id}`}>
                        <tr style={{color:'#2d32aa'}}>
                          <td><h5 className="bi bi-person mt-2" /></td>
                          <td ><i className="bi bi-pencil" style={{fontSize: "10px",verticalAlign: 'top'}}></i></td>
                        </tr>
                      
                      </a>
                      :
                      <a className="justify-content-right text-center ml-5 mb-1" data-bs-toggle='tooltip' data-bs-placement="top" title='Edit Company' type="button" style={{border: 'none', backgroundColor: 'white',margin: '0px 0px 0px 0px', color:'#2d32aa'}}
                      href={`/editCompany/${companyProjects.id}`}>
                        <h5 className="bi bi-pencil mt-2" style={{fontSize: "18px"}}></h5> 
                         
                      </a>
                      }
                      
                      </>
                      
                       :<></>
                    }
                    </div>
                   
                    <div className="col-1  d-flex justify-content-end mt-1 pe-0">
                      {companyProjects && companyProjects.userCanAddProject === true
                      ?
                      <button type="button" className='openModalBtn pe-0' onClick={() => addNewTstProject(companyProjects.id,companyProjects.name)} data-bs-toggle='tooltip' data-bs-placement="top"  title='Add New Project' style={{border: 'none', backgroundColor: 'white'}}>
                        
                          <h1 style={{color:'#2d32aa'}} width={40} height={40}>+</h1> 
                        </button>
                        :<></>
                      }
                    
                     
                       
                     
                      </div>
                      <div className="col-1 d-flex justify-content-center">
                      <button className={index!=0?"accordion-button collapsed buttonMargin":"accordion-button buttonMargin"} type="button" data-bs-toggle="collapse" data-bs-target={"#pannelcollapseTwo" + companyProjects.id} aria-expanded={index!=0?'false':'true'} aria-controls=
                      
                      {"#pannelcollapseTwo" + companyProjects.id}
                      
                      
                      >
                      </button>
                    </div>
                  </div>         
                   <div id={"pannelcollapseTwo" + companyProjects.id} className={index!=0?"accordion-collapse collapse":"accordion-collapse collapse show"} aria-labelledby={companyProjects.id} data-bs-parent="#accordionExample" style={{borderTop: '1px solid skyblue'}}>
            <div className="accordion-body">
            {companyProjects.projects.map( cproject=> {
return (
<div class="apim-project-row ">
<div class={cproject.projectMode} data-bs-toggle='tooltip' title={cproject.projectMode}></div>
  <span class="head-sub-title">
    <a id={cproject.id} class="apim-project-link " href={`/projects/${companyProjects.id}/${cproject.id}`} data-html="">{cproject.name}</a>
    </span>
      </div> 
              );
            })}
            </div>
          </div>
        </div>
      </div>
)})}
    </div>
    {modalOpen &&<ModalDialog ModalHeading={"abc"} setOpenModal={setModalOpen} companyName={companyName}companyID={companyId}/>}
  </div>
  </div>
    )})
  }
export default CompanyAccordion;